<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                    <!-- <router-link :to="{ name: 'enterprise-dashboard', params: { realm: this.$route.params.realmId } }">Enterprise</router-link> --> <!-- TODO: missing enterprise id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-app-bar-title>My session</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="refreshUserSession">
                                <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0">Expires</p>
                            <p class="mb-0 pb-0">
                                <span>{{ authenticatedExpiresText }}</span>
                                <!-- <v-btn class="indigo--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>
                            <p class="text-overline mb-0 mt-8">Next refresh</p>
                            <p class="mb-0 pb-0">
                                <span>{{ nextRefreshText }}</span>
                                <!-- <v-btn class="indigo--text no-print" @click="dialogEditDisplayName = true" icon>
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                                </v-btn> -->
                            </p>
                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Session Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.session, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { client } from '@/client';
// import { toText } from '@/sdk/time';

export default {
    data: () => ({
        sessionExpiresText: null,
        refreshAfter: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        authenticatedExpiresText() {
            if (typeof this.session.authenticatedNotAfter === 'number' && this.session.authenticatedNotAfter > 0) {
                // return toText(this.session.authenticatedNotAfter - Date.now());
                return new Date(this.session.authenticatedNotAfter).toString();
            }
            return undefined;
        },
        nextRefreshText() {
            if (typeof this.session.nextRefresh === 'number' && this.session.nextRefresh > 0) {
                // return toText(this.session.nextRefresh - Date.now());
                return new Date(this.session.nextRefresh).toString();
            }
            return undefined;
        },
    },
    watch: {
        focus() {
            // this.refreshUserSession(); // App.vue already calls refresh which is loadSession and loadUser every time window gains focus
        },
    },
    methods: {
        async refreshUserSession() {
            try {
                this.$store.commit('loading', { refreshUserSession: true });
                await this.$store.dispatch('refresh');
            } catch (err) {
                console.error('failed to load session', err);
            } finally {
                this.$store.commit('loading', { refreshUserSession: false });
            }
        },
    },
    mounted() {
        this.refreshUserSession();
    },
};
</script>
